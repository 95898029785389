.video{
    width: 100%;
    height: 100vh;
    background-color: #222222;
    display: grid;
    place-items: center;
}
.video-card{
    padding: 20px 5vw;
    border-radius: 10px;
    background-color: #ffffff;
}
.video-card-2{
    padding: 20px;
    max-width: 364px;
    border-radius: 10px;
    background-color: #ffffff;
}
.img_mm_01 {
    height: 80px;
    width: 80px;
    margin: 18px auto 0 auto;
    display: block;
    border-radius: 50%;
}
.ree_01 {
    display: grid;
    place-items: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #008000b0;
    border: none;
    rotate: -137deg;
}
.ree_02{
    display: grid;
    place-items: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #f70202a8;
    border: none;
    rotate: -224deg;
}
.btn-holder{
    display: flex;
    justify-content: space-between;
    align-content: center;
}
.widgets h3 {
    font-size: 33px;
    font-weight: 700;
}
.lll_uu_ss_01, .lll_uu_ss_01:hover {
    text-decoration: none;
    background-color: #990033;
    color: #ffffff;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    align-content: space-around;
    flex-wrap: nowrap;
    padding: 10px;
    font-weight: 600;
    border-radius: 5px;
}
.home-bg-2{
    background-color: #222222;
    width: 100%;
    min-height: 100vh;
    display: grid;
    place-items: center;
}