/*=========== sidebar area start here========== */
*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

a,
button {
  cursor: default;
}

button {
  color: inherit;
  background: transparent;
  border: none;
  outline: none;
}

.no-transition {
  transition: none !important;
}
.open-mobile-menu i {
  font-size: 30px;
}
.container-fluid {
  padding: 5px 11px;
}

.side-btn {
  padding: 0.375rem 35px;
  font-size: 1rem;
  background: #c71585;
  color: #fff;
  border-radius: 3px;
  margin: 0 10px;
  margin-top: 30px;
}
.side-btn:hover {
  color: #fff;
}
button.close-mobile-menu {
  position: absolute;
  right: 10px;
}

.open-mobile-menu svg {
  color: #c12525;
}

.post-btn {
  padding: 5px 11px 7px 11px;
  color: #fff;
  border-radius: 3px;
  font-size: 1rem;
  line-height: 1.5;
  background: #c71585;
  transition: 0.3s;
}
.post-btn:hover {
  background: #c12525;
  color: #fff;
}
.dwn-link {
  color: #c71885;
  text-decoration: underline;
  font-size: 14px;
}
.dwn-link:hover {
  color: #c71885;
}

.header .navbar {
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-basis: auto;
  align-items: center;
  justify-content: space-between;
}
.header .navbar .vertical-logo {
  font-size: 1.7rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #d93622;
}
.header .navbar .top-menu-wrapper {
  color: #221f1f;
}
.header .navbar .top-menu-wrapper::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  transition: background 0.5s;
}
.header .navbar .open-mobile-menu i {
  color: #221f1f;
}
.header .navbar .top-menu {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
  transform: translate3d(-100%, 0, 0);
  transition: transform 0.4s cubic-bezier(0.23, 1, 0.32, 1);
}
.header .navbar .top-menu {
  display: flex;
  flex-direction: column;
  width: 180px;
  overflow-y: auto;
  padding: 0;
  background: #ffffff;
}
.header .navbar .top-menu-wrapper.show-offcanvas::before {
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
.header .navbar .top-menu-wrapper.show-offcanvas .panel,
.header .navbar .top-menu-wrapper.show-offcanvas .top-menu {
  transform: translate3d(0, 0, 0);
  transition-duration: 0.7s;
}
.header .navbar .top-menu-wrapper.show-offcanvas .top-menu {
  transition-delay: 0.2s;
}
.side-home {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  background: #efefef;
  padding: 5px 0;
  transition: 0.2s;
  margin-top: 15px;
}
.side-home:hover {
  background: #dfe2e6;
}
.side-home a:hover {
  text-decoration: underline;
}
.header .navbar ul li a {
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  color: #000;
  transition: color 0.35s ease-out;
}
.header .navbar ul li a:hover {
  color: #000;
}
.header .navbar .has-dropdown i {
  display: none;
}
.header .navbar .sub-menu {
  padding: 0.5rem 1.5rem 0 1.5rem;
}
.header .navbar .sub-menu a {
  text-transform: capitalize;
  font-size: 1rem;
  font-weight: 400;
  margin-top: 0rem;
}
.header .navbar .top-menu li + li {
  padding: 5px 0;
  background: #efefef;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  transition: 0.2s;
}
.header .navbar .top-menu li + li:hover {
  background: #dfe2e6;
}
.header .navbar .top-menu li + li a:hover {
  text-decoration: underline;
}
.header .navbar .top-menu .mob-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}
.header .navbar .top-menu .mob-block .logo {
  font-size: 1.7rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #d93622;
}
.header .navbar .top-menu .mob-block i {
  color: #221f1f;
}

.navbar {
  margin: 0;
  padding: 0;
}

a.nav-link.text-white {
  color: rgba(255, 255, 255, 0.87);
  opacity: 0.7;
  font-weight: 400;
  font-size: 14px;
}

.social-login img {
  width: 24px;
}
a {
  text-decoration: none;
}

.card {
  font-family: sans-serif;
  width: 350px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3em;
  border-radius: none;

  border: none;
}
.form-card {
  box-shadow: -1px -1px 10px 4px rgba(204, 202, 204, 0.31);
  padding: 23px;
  margin-top: 5px;
  background-color: #ffff;
}
.title {
  /* text-align: center; */
  /* font-weight: bold;
     */
  font-size: 28px;
  margin: 0;
}

.email-login {
  display: flex;
  flex-direction: column;
}
.email-login label {
  color: #212529;
  font-size: 14px;
}

input[type="text"],
input[type="password"] {
  padding: 4px 10px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-top: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}
input::placeholder {
  font-size: 14px;
}
.cta-btn {
  background-color: #c71585;
  color: white;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 200px;
  border-radius: 3px;
  border: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  transition: 0.3s;
}
.cta-btn:hover {
  background-color: #c12525;
}

.cta-btn-1 {
  background-color: #17a2b8;
  color: white;
  margin-top: 5px;
  margin-bottom: 20px;
  width: 200px;
  border-radius: 3px;
  border: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  line-height: 1.5;
  transition: 0.3s;
}
.cta-btn-1:hover {
  background-color: #138496;
}

.button-area {
  text-align: center;
}
.forget-pass {
  text-decoration: underline;
  display: block;
  color: #333;
  font-size: 14px;
}
.forget-pass:hover {
  color: red;
}

footer {
  background: #f5f5f5;
  padding-top: 10px;
}
.footer-list ul li {
  display: inline-block;
  padding: 0 2px;
  color: #c12525;
  font-size: 14px;
}
.footer-list ul li a:hover {
  text-decoration: underline;
  color: #c12525;
}

.tear {
  color: #000;
}

/*============= eros login form start here ===============*/
HTML CSSResult Skip Results Iframe * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.erosads-nav {
  background-color: #000000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
  padding: 8px 0;
}
.eros-logo img {
  height: 33px;
  width: 110px;
  object-fit: scale-down;
}
.eros-nav li {
  color: rgba(255, 255, 255, 0.87);
  margin-left: 30px;
}
.eros-nav li a:hover {
  color: rgba(255, 255, 255, 0.87);
}
.intro-text h4 {
  font-size: 16px;
  margin-bottom: 40px;
  color: #fff;
}
.eros-form {
  margin-top: 50px;
}
.eros-login {
  margin-top: 20px;
}
.eros-card {
  width: 100%;
  height: 480px;
  background: #000;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  padding: 24px 24px 46px;
}

.eros-card h2 {
  color: #0a66c3;
  font-size: 1.6rem;
}

.eros-card h2 i {
  font-size: 1.8rem;
  padding-left: 0.1rem;
}

.input {
  position: relative;
  width: 100%;
  height: 50px;
}
.eros-email {
  margin-bottom: 43px;
}
.eros-pass {
  margin-bottom: 43px;
}
.input input {
  width: 100%;
  height: 60px;
  padding-top: 1.1rem;
  padding-left: 9px;
  outline: none;
  border: 1px solid #8c8c8c;
  border-bottom: 2px solid transparent;
  border-radius: 5px;
  transition: 0.2s;
  background: #ededed;
}
.eros-pass input {
  padding-right: 45px;
}
.star {
  color: #fe4a49;
}
.input label {
  position: absolute;
  top: 48%;
  left: 10px;
  font-size: 1.1rem;
  color: #8c8c8c;
  transition: 0.2s;
  background-color: transparent;
}

.input input:focus ~ label,
.input input:valid ~ label {
  top: 25%;
  font-size: 0.8rem;
  font-weight: 400;
  color: #efbe00;
}

.input input:focus {
  border-bottom: 3px solid #efbe00;
}
.eros-chake {
  display: flex;
  justify-content: space-between;
}

.eros-chake label {
  color: #fff;
  /* margin-right: 25px; */
}
.eros-chake a {
  margin-left: 50px;
}

.forgot-pass-link {
  width: 140px;
  padding: 0.2rem;
  text-align: center;
  text-decoration: none;
  font-weight: bolder;
  color: #efbe00;
  font-size: 16px;
  font-weight: 300;
  transition: 0.3s;
  border-radius: 12px;
}

.forgot-pass-link:hover {
  text-decoration: underline;
  color: #efbe00;
}
.toggle {
  background: none;
  border: none;
  color: #000;
  /*display: none;*/
  font-size: 20px;
  font-weight: 600;
  /*padding: .5em;*/
  position: absolute;
  right: 0.75em;
  top: 22px;
  z-index: 9;
}
.eros-pass button {
  outline: none;
}

.input-chake {
  width: 1.1em;
  height: 1.1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid #999;
}

.input-chake:checked {
  background-color: #efbe00;
  border-color: #efbe00;
}

.eros-btn {
  height: 40px;
  width: 100%;
  background: transparent;
  border: 2px solid #efbe00;
  color: #efbe00;
  border-radius: 4px;
  text-transform: uppercase;
  margin-top: 33px;
  transition: 0.3s;
}
.eros-btn:hover {
  background: #bc9500;
  color: #fff;
}
p.footertext {
  color: rgba(255, 255, 255, 0.87);
}

.eros-btn2 {
  height: 40px;
  width: 100%;
  background: transparent;
  border: 2px solid #fff;
  color: #fff;
  border-radius: 4px;
  text-transform: uppercase;
  margin-top: 15px;
  transition: 0.3s;
}
.eros-btn2:hover {
  background: #666666;
}

.join-link {
  text-align: center;
}

.join-link a {
  text-decoration: none;
  font-weight: bolder;
  color: #0a66c3;
}

/*============ eros content area start here ==============*/
.eros-content {
  color: #fff;
  margin-top: 20px;
  margin-left: 20px;
}
.eros-content h5 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 14px;
}
.eros-content p {
  font-size: 14px;
  margin-bottom: 20px;
  line-height: 21px;
  font-weight: 300;
}

.what-does {
  margin-top: 40px;
}

.eros-footer {
  background-color: #000;
  margin-top: 47px;
}
.efooter-logo h2 {
  font-size: 22px;
  color: #fff;
  font-weight: 600;
}
.efooter-list ul li {
  display: block;
  color: rgba(255, 255, 255, 0.87);
  margin-bottom: 15px;
  font-size: 14px;
  transition: 0.3s;
}
.efooter-list ul li a {
  font-size: 12px;
  font-weight: 300;
}
.efooter-list ul li a:hover {
  color: #efbe00;
}
.big-content {
  /* margin-left: 30px; */
  padding-left: 50px;
}
.eros-ftext {
  color: rgba(255, 255, 255, 0.87);
}
.efoter-ip {
  color: #efbe00;
}

@media (max-width: 991px) {
  /* .eros-logo img {
    margin-left: 0;
  } */
  .erosads-nav .navbar .eros-logo {
    text-align: center;
  }
  .eros-card {
    height: 480px;
    background: transparent;
  }
  .big-content {
    /* margin-left: 30px; */
    padding-left: 11px;
  }
}

/* ================== ts escort area start here =============== */

.ts-top ul li {
  display: inline-block;
  padding: 0 10px;
  cursor: pointer;
  margin-top: 10px;
}
.ts-item a {
  padding: 0 3px 7px 3px;
  margin-top: 8px;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #337ab7;
  transition: 0.3s;
}
.ts-item a:hover {
  background-color: #f8f8f8;
  border: 1px solid #f1f1f1;
}
.ts-item a i {
  position: absolute;
  margin-left: 31px;
  margin-top: -7px;
}

/* ================== ts escort area start here =============== */

.ts-logo img {
  height: 50px;
  width: 200px;
}

.ts-top ul li {
  display: inline-block;
  padding: 0 10px;
  cursor: pointer;
  margin-top: 10px;
}
.ts-top .advertise a {
  color: #e32424;
}
.ts-top .advertise a:hover {
  color: #e32424;
}
.ts-top ul li a:hover {
  color: #000;
}
.ts-item a {
  padding: 0 3px 7px 3px;
  margin-top: 8px;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #337ab7;
  transition: 0.3s;
}
.ts-item a:hover {
  background-color: #f8f8f8;
  border: 1px solid #f1f1f1;
}
.ts-item a i {
  position: absolute;
  margin-left: 31px;
  margin-top: -7px;
}
.ts-item-lo a {
  padding: 0 3px 7px 3px;
  margin-top: 7px;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #337ab7;
  transition: 0.3s;
}
.ts-item-lo a:hover {
  background-color: #f8f8f8;
  border: 1px solid #f1f1f1;
}
.ts-item-lo a i {
  position: absolute;
  margin-left: 53px;
  margin-top: -8px;
}
.navbar-light .navbar-nav .nav-link {
  color: #337ac7;
}
.navbar-light .navbar-nav .english {
  color: #e32424;
  font-weight: 600;
}
.navbar-light .navbar-nav .english:hover {
  color: #e32424;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #337ac7;
}

.ts-form-area {
  background-color: #f1f1f1;
}
.ts-login {
  margin-top: 22px;
}
.ts-login h2 {
  font-size: 31px;
  font-weight: 400;
  margin-left: -15px;
}
.ts-red {
  color: red;
}
.ts-form {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 6px;
  -moz-box-shadow: 0 0 3px #ccc;
  -webkit-box-shadow: 0 0 3px #ccc;
  box-shadow: 0 0 3px #ccc;
  padding: 10px;
  margin-bottom: 20px;
}

.ts-form h4 {
  color: #e32424;
  font-weight: bold;
  font-size: 1.4em;
  border-bottom: 0px none;
  margin-bottom: 10px;
}
.ts-form .ts-top-content {
  color: #666;
  font-size: 0.9em;
  background-color: #f6f7d9;
  padding: 5px;
  border: 1px solid #f7fdae;
  border-radius: 4px;
}
.ts-form .ts-top-content span {
  color: black;
  border: 1px solid #ccc;
  padding: 3px 8px;
  border-radius: 4px;
  margin: 3px 0px;
  background-color: white;
}
.ts-form .ts-top-content span i {
  font-size: 11px;
}
.toggle-ts {
  position: absolute;
  margin-top: 13px;
  margin-left: -25px;
}
.form-control {
  font-size: 17px;
  color: #555;
}
.pass-fild {
  text-align: right;
}
.ts-input-area .pass-control {
  padding-right: 30px;
}
.tsform-chake {
  margin-left: 55px;
}
.form-check-input {
  width: 15px;
  height: 15px;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: transparent;
  border: 1px solid #999;
}
.ts-input-area label {
  color: #333;
  font-size: 16px;
  font-weight: 700;
  margin-top: -10px;
}
.ts-input-area .ts-email {
  padding-left: 5px;
}

.ts-enter {
  padding: 8px 90px;
  background-color: #1f7cc3;
  color: #fff;
  border-radius: 5px;
  margin-left: 30px;
}
.ts-enter:hover {
  background-color: #286090;
  color: #fff;
}
.form-check .ts-label {
  font-size: 15px;
}

.ts-forgot p {
  font-weight: bold;
  font-size: 0.9em;
  line-height: 16px;
  margin: 5px 0 20px 0;
}
.ts-forgot p a {
  color: #1f7cc3;
}
.ts-forgot p a:hover {
  color: #1f7cc3;
  text-decoration: underline;
}

.ts-member {
  background-color: #dadada;
  min-height: 320px;
  border: 1px solid #ccc;
  border-radius: 6px;
  -moz-box-shadow: 0 0 3px #ccc;
  -webkit-box-shadow: 0 0 3px #ccc;
  box-shadow: 0 0 3px #ccc;
  padding: 10px;
  margin-bottom: 20px;
  margin-top: 67px;
}
.ts-member h4 {
  color: #e32424;
  font-weight: 700;
  font-size: 1.4em;
  border-bottom: 0px none;
  margin-bottom: 10px;
}
.ts-member h6 {
  font-weight: bold;
  line-height: 16px;
  margin-bottom: 20px;
  font-size: 0.9em;
}
.ts-member ul {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 15px;
}
.ts-member ul li img {
  margin-bottom: -25px;
}
.ts-member ul li span {
  margin-left: 29px;
}

.creat-btn {
  margin-top: 50px;
}
.creat-btn .ts-creat {
  padding: 8px 90px;
  background-color: #286090;
  color: #fff;
  border-radius: 5px;
}

.ts-footer-area {
  background-color: #fff;
}

.ft-border {
  border-right: 1px solid #e7e7e7;
}
.ts-footer-list ul {
  margin: 0;
  padding: 0;
  padding-left: 10px;
}
.ts-footer-list ul h5 {
  color: #1f7cc3;
  font-size: 18px;
  font-weight: 400;
  padding-top: 10px;
}
.ft-border h5 {
  color: #1f7cc3;
  font-size: 18px;
  font-weight: 400;
  padding-top: 10px;
}
.ts-footer-img h5 {
  color: #1f7cc3;
  font-size: 18px;
  font-weight: 400;
  padding-top: 10px;
}
.ts-footer-list ul li {
  font-size: 14px;
}
.ts-footer-list ul li a {
  color: black;
  letter-spacing: 0.5px;
}
.ts-footer-list ul li a:hover {
  text-decoration: underline;
}
.ts-footer-list ul li i {
  font-size: 3px;
  color: #e32424;
  padding-right: 7px;
}
.loca-top {
  margin-bottom: 10px;
}
.loca-top p {
  margin: 0;
  padding: 0;
  font-size: 13px;
  letter-spacing: -0.3px;
}
.loca-top a {
  font-size: 14px;
}
.lock-link a {
  font-size: 14px;
  letter-spacing: 0.5px;
  color: #000;
}
.lock-link a:hover {
  text-decoration: underline;
  color: #000;
}

.ts-footer-img {
  text-align: center;
}
.ts-footer-img img {
  margin-left: 50px;
  margin-top: 10px;
}
.coppyr-text ul {
  margin: 0;
  padding: 0;
  padding: 2px 0 6px 0;
  border-top: 1px solid #e7e7e7;
}
.coppyr-text ul li {
  display: inline-block;
  font-size: 10px;
  color: #1f7cc3;
}
.coppyr-text ul li a:hover {
  text-decoration: underline;
}

@media (max-width: 991px) {
  .ts-login h2 {
    margin-left: 0px;
  }
  .pass-fild {
    text-align: left;
    margin-left: 0;
    margin-bottom: -10px;
  }
  .ts-input-area .ts-email {
    padding-left: 0;
    margin-bottom: -30px;
  }
  .email-fild {
    margin-top: -20px;
  }
  .ts-input-area label {
    margin-top: 0;
  }
  .tsform-chake {
    margin-left: 10px;
  }
  .ts-enter {
    margin-left: 0;
    margin-top: 50px;
  }
  .ts-footer-img {
    text-align: left;
  }
  .ts-footer-img img {
    margin-left: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .ts-member {
    margin-top: 20px;
  }
}

@media (max-width: 768px) {
  .enter-btn {
    margin-top: 50px;
    position: absolute;
    left: 0;
  }
  .ts-forgot p {
    padding-top: 50px;
  }
}
.navdvi{
  padding: 0 4.5%;
}
@media (max-width: 992px) {
  .erosads-nav> .container {
    padding: 0  0 !important;
}
  p.footertext {
    display: inline;
    font-size: 12px;
    color: #b1b1b1;
  }
}

@media (max-width: 555px) {
  /* .nav-custom{
    flex-direction: row-reverse;
  }
  .eros-logo{
    transform: translateX(-100%);
  } */
  .nav-custom {
    position: relative;
  }
  .eros-logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}
.main-row {
  padding: 0 7%;
}

@media screen and (max-width: 1400px) {
  .eros-login {
    width: 385px !important;
    margin-top: 20px;
  }
  .main-row {
    padding: 0 2%;
    gap: 2%;
  }
}
@media screen and (max-width: 1200px) {
  .eros-login {
    width: 365px !important;
    margin-top: 20px;
  }
  .main-row {
    gap: 8%;
  }
}
@media screen and (max-width: 991px) {

  .eros-login {
    margin-top: 20px;
  }
  .main-row {
    padding: 0;
    gap: 8%;
  }
  .container.fromtab {
    margin: 0;
    max-width: 100%;
  }
  .eros-content {
    width: 90%;
  }
  .eros-form {
    padding: 0 !important;
  }
}
@media screen and (max-width: 768px) {
  .eros-login {
    /* margin:  0 auto; */
  }
}
@media screen and (max-width: 460px) {
  button.navbar-toggler {
    margin-left: -20% !important;
  }
}